.textarea--edit {
  padding: 17px 17px 17px 18px;
  width: 100%;
  height: 115px;
  background: #ffefef;
  border: 2px solid #ffd2d1;
  border-radius: 14px;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.37;
  color: #fd5e5a;
  resize: none;
  &::placeholder {
    color: #fd5e5a;
    opacity: 0.6;
  }
}
